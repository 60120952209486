@import './_vars.scss';

/********************
 *Bootstrap Overide**
*********************/

a {
	text-decoration: none !important;
}

.navbar,
.navbar-default,
nav,
.nav {
	z-index: 999;
	box-shadow: none !important;
}

.navbar {
	border-radius: 0px !important;
	margin-bottom: 0px !important;
}

.navbar-right {
	margin-right: -30px;
}

@media (min-width: 521px) {
	.navbar-brand {
		height: 100px !important;
		font-size: 22pt !important;
		padding: 0 !important;
		margin-left: 0px !important;
	}

	.navbar-brand img {
		height: auto;
		max-height: 100px;
		max-width: 180px;
		margin: auto;
		padding: 5px !important;
	}
}

@media (max-width: 520px) {
	.navbar-brand {
		height: 100px !important;
		font-size: 22pt !important;
		padding: 0 !important;
		margin-left: 0px !important;
	}

	.navbar-brand img {
		height: auto;
		max-width: 180px;
		max-height: 100px;
		margin: auto;
		padding: 5px !important;
	}
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
	color: $secondary;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
	/* ...and now override with proper CSS property */
	transition:color .4s ease-out, background .3s ease-in;
}

.navbar-default .navbar-brand {
	color: #FFD700;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
	/* ...and now override with proper CSS property */
	transition:color .4s ease-out, background .3s ease-in;
}

.navbar-default .navbar-nav > li > a {
	color: $navbar;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
	/* ...and now override with proper CSS property */
	transition:color .4s ease-out, background .3s ease-in;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: $navbar;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
	/* ...and now override with proper CSS property */
	transition:color .4s ease-out, background .3s ease-in;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	color: $navbar;
}

.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
	color: #fff;
	background-color: transparent;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
	color: $navbar;
}

.navbar-toggle {
	margin-top: 33px !important;
}

@media (min-width: $dropdown-header-max-width) {
	.navbar-nav > li > a {
		padding-top: 40px !important;
		padding-bottom: 40px !important;
	}

	.navbar-nav > li > a {
		padding-left: 30px !important;
		padding-right: 30px !important;
		font-size: 18pt !important;
	}
}

@media(max-width: $dropdown-header-min-width) {
	.navbar-nav > li > a {
		font-size: 18pt !important;
	}

	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: $primary;
	}

	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #000000;
	}

	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

/*************
 *Global CSS**
**************/

html {
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s; /* Firefox < 16 */
	-ms-animation: fadein 2s; /* Internet Explorer */
	-o-animation: fadein 2s; /* Opera < 12.1 */
	animation: fadein 2s;
}

html, body {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	text-align: left;
	background-color: #F0F0F0;
	color: #000000;
	font-weight: normal;
	overflow-x: hidden;
	overflow-y: auto;
	min-height: 100%;
	font-family: 'Open Sans', sans-serif;
}

img {
	max-width: 100%;
}

@media (min-width: 401px) {
	section#main {
		float: left;
		position: relative;
		width: 100%;
		padding-bottom: 266px;
	}
}

@media (max-width: 400px) {
	section#main {
		float: left;
		position: relative;
		width: 100%;
		padding-bottom: 266px;
	}
}

.app-container {
	overflow-x: hidden;
	position: relative;
	margin: 0 auto;
	height: auto !important;
	min-height: 100%;
	height: 100%;
}

.clear:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.navbar {
	margin-bottom: 0px !important;
	border-width: 0 0 1px 0;
	border-color: transparent;
}

.dropdown-item {
	color: rgba(255, 255, 255, 0.5);
	font-size: 18pt !important;
}

.table {
	background-color: #FFFFFF;
}

.table tbody tr td {
	padding: 20px !important;
}
