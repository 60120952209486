@use '@angular/material' as mat;

@import 'bootstrap/scss/bootstrap';
@import '../angular';
@import '../_bootstrapOverrides';

@include mat.core();

$my-primary: mat.m2-define-palette(mat.$m2-blue-gray-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-indigo-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme((
	color: (
		primary: $my-primary,
		accent: $my-accent,
	)
));

@include mat.all-component-themes($my-theme);

$overlay-color: #fefefe;
$splash-text-color: #000000;

/********************
 *Index Section CSS**
 ********************/

.overlay {
	position: absolute;
	width: 100%;
	height: 100vh;
	z-index: 2;
	background-color: $overlay-color;
	opacity: .5;
}

.splash-text-container-outer {
	display: table;
	height: 100%;
	width: 100%;
	margin-top: -100px;
	padding-top: 100px;
	float: left;
}

.splash-text-container {
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	color: $splash-text-color;
	z-index: 99;
	position: relative;
	padding: 0 10px;
}

.splash-text-container a {
	color: $primary;
	text-decoration: none;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

.splash-text-container a:hover {
	color: $secondary;
	text-decoration: none;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

img.header-image {
	width: 200px;
	display: block;
	margin: auto;
}

.splash-text-container p.main-message {
	display: block;
}

.splash-text-container p.sub-message {
	display: block;
}

@media(min-width: $dropdown-header-min-width) {
	.splash-text-container p.main-message {
		font-size: 50pt;
	}

	.splash-text-container p.sub-message {
		font-size: 26pt;
	}

	.dropdown-header {
		font-size: 26pt !important;
	}
	.dropdown-menu-large a {
		font-size: 14pt;
	}
	.dropdown-menu-large .dropdown-header {
		color: $secondary;
	}
	.dropdown-menu-large > li > ul > li > a {
		color: #333333;
	}
}

@media(max-width: $dropdown-header-max-width) {
	.splash-text-container p.main-message {
		font-size: 30pt;
	}

	.splash-text-container p.sub-message {
		font-size: 20pt;
	}

	.dropdown-menu-large > li > ul > li > a {
		color: #FFFFFF;
		font-size: 16pt;
		line-height: 1.5;
	}
	.dropdown-menu-large .dropdown-header {
		color: $primary;
		font-size: 18pt;
		padding: 3px 15px !important;
	}
	.dropdown-menu-large {
		margin-left: 0 ;
		margin-right: 0 ;
	}
	.dropdown-menu-large > li {
		margin-bottom: 30px;
	}
	.dropdown-menu-large > li:last-child {
		margin-bottom: 0;
	}
	.navbar-default .navbar-nav > .open > a {
		font-size: 18pt;
	}
}

/*************
 *Footer CSS**
**************/

footer {
	bottom: 0;
	position: absolute;
	background-color: $footer-background-color;
	color: $footer-color;
	width: 100%;
	padding-top: 10px;
	display: block;
}

footer a {
	color: $primary;
	text-decoration: none;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

footer a:hover {
	color: $secondary;
	text-decoration: none;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

footer .fab {
	font-size: 42pt;
}

footer .fa-facebook,
footer .fa-twitter,
footer .fa-instagram {
	color: $primary;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

footer .fa-facebook:hover
footer .fa-twitter:hover,
footer .fa-instagram:hover {
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

footer .fa-facebook:hover {
	color: #3b5998;
}

footer .fa-twitter:hover {
	color: #4099FF;
}

footer .fa-instagram:hover {
	color: #fbad50;
}

.footer-legal {
	width: 100%;
	position: relative;
	padding: 20px 0 10px 0;
	text-align: center;
}

.footer-content {
	padding: 30px 10px;
	position: relative;
	width: 100%;
	font-size: 13pt;
	color: $footer-color;
	text-align: center;
}

.social-footer {
	padding: 10px;
	position: relative;
	width: 100%;
	font-size: 13pt;
	color: $footer-color;
	text-align: center;
}
