$primary:           #00AAD1;
$secondary:         #fff820;
$navbar:            #ffffff;
$dark:              #343a40;

$navbar-light-color: #FFFFFF;

$footer-color:            #ffffff;
$footer-background-color: #000000;

$dropdown-header-min-width:   1230px;
$dropdown-header-max-width:   $dropdown-header-min-width - 1;
$max-width-xs:                767px;
$min-width-from-xs:           $max-width-xs + 1;

$theme-colors: (
	"primary":    $primary,
	"secondary":  $secondary,
	"dark":       $dark,
);
