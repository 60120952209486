$primary: #FF0000;
$table-header-font-size: 18px;
$table-font-size: 14px;
$table-padding: 12px;

html, body {
	height: 100%;
}

body {
	margin: 0;
}

.element-row {
	height: auto;
}

.mat-table-td,
.mat-footer-cell,
.mat-mdc-footer-cell {
	padding-top: 26px !important;
	padding-bottom: 26px !important;
	font-size: $table-font-size !important;
}

.mat-footer-cell,
.mat-mdc-footer-cell {
	font-weight: bold;
}

.mat-header-cell,
.mat-mdc-header-cell {
	font-size: $table-header-font-size !important;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.mdc-card__actions {
	margin: $table-padding !important;
	padding: $table-padding !important;
}

.filters {
	background-color: #FFFFFF;
	padding: 15px;
	border-radius: 5px;
	margin-bottom: 20px;
}

.class-table-list {
	margin-bottom: 0;
	padding-left: 0;
}

.mdc-card__actions {
	margin: 10px !important;
	padding: 5px !important;
}

.mat-card-avatar {
	margin-right: 10px;
}

.mat-card-header-text {
	margin: 0 !important;
}

.mat-raised-button,
.mat-radio-button {
	margin: 0.1em !important;
}

.mat-header-cell {
	font-size: 13pt;
}

.mat-header-row,
.mat-row {
	height: auto !important;
	padding: 0px !important;
}

.mat-cell,
.mat-header-cell {
	padding: 10px;
}

.mat-sidenav-content {
	overflow: visible !important;
}

.mat-mdc-card-content {
	margin-bottom: 0 !important;
}

mat-sidenav-container,
.mat-sidenav-backdrop {
	background-color: #e8e8e8 !important;
}

.cdk-overlay-pane {
	max-width: none !important;
}

.mat-paginator-container {
	background-color: #FFFFFF;
}

@media (max-width: 998px) {
	.mat-mdc-card-content {
		overflow-y: scroll;
	}
}

.mat-drawer-content {
	min-height: 100vh !important;
}

.list-flex {
	max-width: 100%;
}

.column-flex {
	display: flex;
	width: 100%;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: flex-start;
}

.dash-flex {
	max-width: 100%;
}

.flex-filter {
	margin: 10px;
	flex-grow: 1;
	display: block;
}

.flex-item {
	margin: 10px;
	flex-grow: 1;
}

h1.count {
	font-weight: 100;
	margin: 0 0 30px 0;
}

@media (min-width: 778px) {
	.list-flex {
		width: 100%;
		display: flex !important;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
		align-content: flex-start;
		box-sizing: border-box;
	}

	.dash-flex {
		flex-direction: row;
		flex-wrap: nowrap;
		display: flex;
		justify-content: space-between;
		align-content: flex-start;
	}
}

@media (max-width: 777px) {
	.list-flex,
	.dash-flex {
		display: block;
	}
}

@media (min-width: 778px) {
	.flex-filter {
		width: 250px;
	}

	.flex-item {
		width: 350px;
	}
}

.fc-day-grid-event {
	cursor: pointer !important;
}

.mat-select-panel {
	background-color: #FFFFFF;
}

.cart-error {
	color: #FF0000;
	border: 1px solid #FF0000;
	border-radius: 5px;
	padding: 15px;
	margin-top: 15px;
}

.cart-error ul {
	margin: 5px 0 !important;
}

.cart-success {
	color: #009933;
	border: 1px solid #009933;
	border-radius: 5px;
	padding: 15px;
	margin-top: 15px;
}

.mat-mdc-card-title {
	font-weight: bold !important;
	font-size: 18pt !important;
}

.mat-badge-content {
	font-family: "Open Sans", sans-serif !important;
	font-size: 10pt !important;
}

.mat-table-td,
.mat-footer-cell,
.mat-mdc-footer-cell {
	font-size: 16px !important;
}
